<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bank2 fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Trục dữ liệu lớn Nông nghiệp Đồng Bằng Sông Cửu Long
            </h2>
            <h6 class="m-0 text-dark fw-light">
                Giới thiệu
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-GioiThieuDetail" data-bs-toggle="tab"
                                    href="#GioiThieuDetail" role="tab" aria-controls="GioiThieuDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Giới thiệu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="GioiThieuDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <img src="/assets/image/GioiThieu_Banner.png" class="rounded-4" style="width: 100%;">
                                         </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                           <h3><b style="color: green;">1. Văn phòng điều phối nông nghiệp nông thôn vùng ĐBSCL - Bộ Nông nghiệp và Phát triển nông thôn</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Địa chỉ : <b>Số 9B đường Cách Mạng Tháng Tám, Phường An Hòa, Quận Ninh Kiều, Thành phố Cần Thơ</b></h5>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b style="color: green;">2. Cục kinh tế hợp tác và Phát triển nông thôn - Bộ nông nghiệp và Phát triển nông thôn (DCRD)</b></h3>
                                         </div>
                                         <div class="col-lg-12 col-sm-12 col-12">
                                             <h5>Địa chỉ : <b>Số 02 đường Ngọc Hà, quận Ba Đình, thành phố Hà Nội</b></h5>
                                         </div>
                                         <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Điện thoại : <b>024 384 387 94</b></h5>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b style="color: green;">3. Các sở nông nghiệp và phát triển nông thôn vùng ĐBSCL</b></h3>
                                         </div>                                         
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b style="color: green;">4. Các chuyên gia và giảng viên đã tham gia hỗ trợ hệ thống</b></h3>
                                         </div>                                         
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3>Đơn vị hỗ trợ kỹ thuật: <b style="color: green;">Công ty Cổ phần Đầu tư Digital Kingdom</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Điện thoại : <b>0902 561 183</b></h5>
                                        </div>  
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Email : <a target="_blank"
                                                href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@dgk.vn&su=Hi_DGJ&body=https://dgk.vn/&tf=1"><b>info@dgk.vn</b></a></h5>
                                        </div>  
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Website : <a target="_blank" href="https://dgk.vn/" title="dgk.vn"><b>dgk.vn</b></a></h5>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Địa chỉ : <a target="_blank" href="https://dgk.vn/lienhe.html" title="Liên hệ"><b>Tầng 1, tòa nhà Packsimex, Số 52 đường Đông Du , phường Bến Nghé, 
                                                quận 01, thành phố Hồ Chí Minh</b></a></h5>
                                        </div>
                                        <hr/>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3>Hỗ trợ kỹ thuật</h3>
                                         </div>
                                         <div class="col-lg-12 col-sm-12 col-12">
                                             <h5>Văn phòng điều phối nông nghiệp nông thôn vùng ĐBSCL :</h5>
                                             <h5><b>Trần Minh Hậu - 093 3956369</b></h5>
                                             <h5><b>Tăng Minh Kỳ - 098 8989652</b></h5>
                                             <h5><b>Hứa Hữa Đức - 077 2807957</b></h5>
                                         </div>  
                                         <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Công ty Cổ phần Đầu tư Digital Kingdom : <b>Vũ - 0907 265 583</b></h5>
                                        </div>                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>