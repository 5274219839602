import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { ToChucToaDoService } from 'src/app/shared/ToChucToaDo.service';
import { ToChucVungTrong } from 'src/app/shared/ToChucVungTrong.model';
import { ToChucVungTrongService } from 'src/app/shared/ToChucVungTrong.service';

@Component({
  selector: 'app-to-chuc-toa-do-detail',
  templateUrl: './to-chuc-toa-do-detail.component.html',
  styleUrls: ['./to-chuc-toa-do-detail.component.css']
})
export class ToChucToaDoDetailComponent implements OnInit {

  ToChucToaDoDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucToaDoSort') ToChucToaDoSort: MatSort;
  @ViewChild('ToChucToaDoPaginator') ToChucToaDoPaginator: MatPaginator;

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  searchString: string = environment.InitializationString;
  domainURL: string = environment.DomainURL;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<ToChucToaDoDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ToChucToaDoService: ToChucToaDoService,
    public ToChucVungTrongService: ToChucVungTrongService,

  ) { }

  ngOnInit(): void {
    this.ToChucToaDoSearch();
  }

  Close() {
    this.dialogRef.close();
  }

  ToChucToaDoGetToList() {
    this.isShowLoading = true;
    this.ToChucToaDoService.baseParameter.ParentID = this.ToChucVungTrongService.formData.ParentID;
    this.ToChucToaDoService.baseParameter.ToChucVungTrongID = this.ToChucVungTrongService.formData.ID;
    this.ToChucToaDoService.GetByToChucVungTrongIDAndEmptyToListAsync().subscribe(
      res => {
        this.ToChucToaDoService.list = (res as ToChucToaDo[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucToaDoService.list001 = this.ToChucToaDoService.list;
        this.ToChucToaDoService.DataSource = new MatTableDataSource(this.ToChucToaDoService.list);
        this.ToChucToaDoService.DataSource.sort = this.ToChucToaDoSort;
        this.ToChucToaDoService.DataSource.paginator = this.ToChucToaDoPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucToaDoSearch() {
    if (this.searchString.length > 0) {
      this.ToChucToaDoService.DataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.ToChucToaDoGetToList();
    }
  }
  ToChucToaDoSave(element: ToChucToaDo) {
    this.isShowLoading = true;
    element.ParentID = this.ToChucVungTrongService.formData.ParentID;
    element.ToChucVungTrongID = this.ToChucVungTrongService.formData.ID;
    this.ToChucToaDoService.formData = element;
    this.ToChucToaDoService.SaveAsync().subscribe(
      res => {
        this.ToChucToaDoSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucToaDoDelete(element: ToChucToaDo) {
    if (confirm(environment.DeleteConfirm)) {
      this.isShowLoading = true;
      this.ToChucToaDoService.baseParameter.ID = element.ID;
      this.ToChucToaDoService.RemoveAsync().subscribe(
        res => {
          this.ToChucToaDoSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
}
